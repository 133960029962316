<template>
  <div>
    <div style="position: relative;" v-for="(fa, faIndex) in sfaSaleDocketProductList" :key="faIndex">
      <a-button style="position: absolute;left: 100px;top: 15px;z-index: 9;height: 28px;"
        @click="addBtn(faIndex, fa.type)" type="primary" v-if="!isDetails">添加</a-button>
      <!-- <a-button style="position: absolute;left: 180px;top: 15px;z-index: 9;height: 28px;" @click="giftsBtn(faIndex,fa.type)" v-if="fa.type != 4 && !isDetails" type="primary">赠送</a-button> -->
      <a-card :title="fa.type == 3 ? '储值卡券' : fa.type == 4 ? '提货券' : fa.type == 1 ? '次票' : ''" :bordered="false">
        <div style="margin-top: 20px;">
          <a-table :columns="columns" bordered :dataSource="fa.sfaCardCouponList" :pagination="false"
            :scroll="{ x: 1500 }">

            <template slot="distributorCode" slot-scope="record">
              <span v-if="record == '20220601001'">优加惠品</span>
              <span v-if="record == '20221212001'">好乐滋</span>
            </template>

            <template slot="mediaType" slot-scope="record">
              <span v-if="record == '1'">电子卡</span>
              <span v-if="record == '2'">实体卡</span>
            </template>
            <template slot="discount" slot-scope="record">
              <span>{{ record }}</span>
            </template>

            <span slot="amountRecharge">
              <span v-if="fa.type == 3">单张充值</span>
              <span v-if="fa.type == 4">档位市场价值</span>
              <span v-if="fa.type == 1">单张充值</span>
            </span>

            <template slot="style" slot-scope="record" style="padding: 0;">
              <img v-if="type == 'details'" style="width: 80px;height: 40px;" :src="record.imagePath" alt="">
              <img v-if="type == 'edit' && record.imagePath" style="width: 80px;height: 40px;" :src="record.imagePath"
                alt="">
              <img v-if="type == 'edit' && !record.imagePath" style="width: 80px;height: 40px;" :src="record" alt="">
              <img v-if="!type" style="width: 80px;height: 40px;" :src="record" alt="">
              <img v-if="type == 'add'" style="width: 80px;height: 40px;" :src="record" alt="">
            </template>

            <template slot="action" slot-scope="record">
              <div v-if="!isDetails">
                <a @click="editChange(record, fa.type)" style="margin-right: 10px;"
                  v-if="hasPermission('UPDATE_CONTACT')">编辑</a>
                <a-popconfirm style="cursor: pointer;" title="您确定要删除吗？" ok-text="是" cancel-text="否"
                  @confirm="delConfirm(record, faIndex)" v-if="hasPermission('DEL_CONTACE')">删除
                </a-popconfirm>
              </div>
              <div v-if="isDetails">
                <a @click="detailsChange(record, fa.type)" style="margin-right: 10px;"
                  v-if="hasPermission('UPDATE_CONTACT')">详情</a>
              </div>
            </template>
          </a-table>

          <div style="display: flex;margin-top: 20px; padding: 0 20px;box-sizing: border-box;">
            <div style="flex: 1;display: flex;margin-right: 20px;">
              <div style="margin-right: 10px;width: 50px;">
                <span style="font-size: 15px;">备注</span> <br>
                <span
                  style="font-family: PingFangSC, PingFang SC;font-weight: 500;color: #999999;font-size: 12px;">(打印)</span>
              </div>
              <div style="width: 100%;">
                <a-textarea :disabled="isDetails" v-model="fa.remarks" style="width: 100%;"
                  placeholder="备注信息"></a-textarea>
              </div>
            </div>
            <div style="flex: 1;display: flex;">
              <div style="margin-right: 10px;width: 90px;">
                <span style="font-size: 15px;">备注</span> <br>
                <span
                  style="font-family: PingFangSC, PingFang SC;font-weight: 500;color: #999999;font-size: 12px;">(不打印，仅个人可见)</span>
              </div>
              <div style="width: 100%;">
                <a-textarea :disabled="isDetails" v-model="fa.printRemarks" style="width: 100%;"
                  placeholder="备注信息"></a-textarea>
              </div>
            </div>
          </div>
        </div>
      </a-card>
    </div>

    <!-- 创建 -->
    <a-modal title="添加" :visible="visible" :confirm-loading="confirmLoading" :maskClosable="false" :footer="null"
      @cancel="cancelChange" :width="780" :z-index="100">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item ref="distributorCode" prop="distributorCode" label="分销商" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="form.distributorCode" style="width: 100%" placeholder="请选择分销商" option-label-prop="label"
              @change="codeChange">
              <a-select-option value="20220601001" label="优加惠品">优加惠品</a-select-option>
              <a-select-option value="20221212001" label="好乐滋">好乐滋</a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('分销商')" />
          </div>
        </a-form-model-item>

        <a-form-model-item required label="卡券产品" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="form.giftId" style="width: 100%" placeholder="请选择卡券产品" :default-value="null">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in giftList"
                @click="selectProd(item.name)" :key="index">
                {{ item.name }}
                <span v-if="item.code">({{ item.code }})</span>
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('卡券产品')" />
          </div>
        </a-form-model-item>
        <!-- 提货券档位 -->
        <a-form-model-item required ref="gearPosition" prop="gearPosition" label="档位" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }" v-if="faIndex == 1">
          <div style="position: relative;">
            <a-select v-model="form.gearPosition" @change="dwChange" style="width: 100%" placeholder="请选择档位">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in dwArr" :key="index">
                {{ item.name }}档
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('提货券档位')" />
          </div>
        </a-form-model-item>
        <!-- 次票档位 -->
        <a-form-model-item required ref="rechargeGearPosition" prop="rechargeGearPosition" label="档位"
          :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }" v-if="faIndex == 2">
          <div style="position: relative;">
            <a-select v-model="form.rechargeGearPosition" @change="cpdwChange" style="width: 100%" placeholder="请选择档位">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in cpdwArr" :key="index">
                {{ item.name }}档
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('次票档位')" />
          </div>
        </a-form-model-item>

        <a-form-model-item v-if="faIndex == 1" required prop="type" label="产品类型" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="form.type" style="width: 100%" placeholder="产品类型">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in prodArr" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('产品类型')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="giftName" prop="giftName" label="礼品卡名称" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="form.giftName" placeholder="请输入礼品卡名称" />
            <a-icon type="question-circle" class="question-icon" @click="openAlert('礼品卡名称')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="category" prop="category" label="卡/券" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="form.category" style="width: 100%" placeholder="请选择卡/券">
              <a-select-option :value="1" label="卡">卡</a-select-option>
              <a-select-option :value="2" label="券">券</a-select-option>
            </a-select>
          </div>
        </a-form-model-item>

        <a-form-model-item ref="customized" prop="customized" label="是否定制印刷卡券" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="form.customized" style="width: 100%" placeholder="请选择是否定制印刷卡券" option-label-prop="label">
              <a-select-option :value="1" label="是">是</a-select-option>
              <a-select-option :value="0" label="否">否</a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('定制卡样')" />
          </div>
        </a-form-model-item>

        <a-form-model-item v-if="form.customized === 1" ref="customerId" prop="customerId" label="客户名称"
          :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <!-- <a-select v-model="form.customerId" style="width: 100%" placeholder="请选择客户"
            :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
            show-search :default-value="null" option-label-prop="label">
            <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in customerData" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select> -->
          <a-select show-search :value="searchVal" placeholder="检索客户名称" :default-active-first-option="false"
            :show-arrow="false" :filter-option="false" :not-found-content="null" @search="fetchUser"
            @change="handleSearchChange">
            <a-select-option v-for="d in customerData" :key="d.name" :label="d.name" :value="d.id">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item ref="styleId" prop="styleId" label="线上卡券样式" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative; width: 200px;cursor: pointer;" @click="getCardStyle()">
            <div class="select-btn" v-if="!selectedCardItem.imagePath">
              <p>选择</p>
            </div>
            <div class="select-btn" style="border: none;" @click="isSelectCard = true" v-else>
              <img :src="selectedCardItem.imagePath" alt="">
            </div>
            <a-icon type="question-circle" class="question-icon" @click.stop="openAlert('卡券样式')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="mediaType" prop="mediaType" label="介质类型" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="form.mediaType" style="width: 100%" placeholder="请选择介质类型">
              <a-select-option :value="1" label="电子卡">
                电子卡
              </a-select-option>
              <a-select-option :value="2" label="实体卡">
                实体卡
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('介质类型')" />
          </div>
        </a-form-model-item>


        <a-form-model-item ref="number" prop="number" label="售卡数量" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="form.number" placeholder="请输入售卡数量" />
            <span class="ipt-text">张</span>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('数量')" />
          </div>
        </a-form-model-item>

        <a-form-model-item v-if="faIndex == 2 || faIndex == 0" ref="amountRecharge" prop="amountRecharge" label="单张充值"
          :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="form.amountRecharge" placeholder="请输入单张充值" />
            <span class="ipt-text">元</span>
          </div>
          <a-icon type="question-circle" class="question-icon" @click="openAlert('充值金额')" />
        </a-form-model-item>

        <a-form-model-item v-if="faIndex == 1" ref="amountRecharge" prop="amountRecharge" label="档位市场价值"
          :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="form.amountRecharge" placeholder="请输入档位市场价值" />
            <span class="ipt-text">元</span>
          </div>
          <a-icon type="question-circle" class="question-icon" @click="openAlert('充值金额')" />
        </a-form-model-item>

        <!-- <a-form-model-item label="单张实收" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input disabled v-model="form.amount" />
            <span class="ipt-text">元</span>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('实收金额')"/>
          </div>
        </a-form-model-item>
        <a-form-model-item label="折扣" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            0%
            <a-icon type="question-circle" class="question-icon" @click="openAlert('折扣')"/>
          </div>
        </a-form-model-item> -->

        <a-form-model-item ref="customized" prop="customized" label="发票" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="form.invoice" style="width: 100%" placeholder="请选择是否需要发票" option-label-prop="label">
              <a-select-option :value="1" label="开票">开票</a-select-option>
              <a-select-option :value="2" label="不开票">不开票</a-select-option>
              <a-select-option :value="3" label="未知">未知</a-select-option>
            </a-select>
          </div>
        </a-form-model-item>

        <a-form-model-item required label="有效期" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-range-picker :value="dateValue"
              :ranges="{ '1年': [moment(), moment().add(1, 'years')], '2年': [moment(), moment().add(2, 'years')], '3年': [moment(), moment().add(3, 'years')] }"
              @change="dateChange" :placeholder="['有效期开始时间', '有效期结束时间']" style="width:100%;" />
            <a-icon type="question-circle" class="question-icon" @click="openAlert('有效期')" />
          </div>
        </a-form-model-item>

        <a-form-model-item required ref="isDelay" prop="isDelay" label="是否允许延期" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="form.isDelay" style="width: 100%" placeholder="请选择是否允许延期" option-label-prop="label">
              <a-select-option :value="1" label="允许延期">允许延期</a-select-option>
              <a-select-option :value="2" label="不允许延期">不允许延期</a-select-option>
              <a-select-option :value="3" label="未知">未知</a-select-option>
            </a-select>
          </div>
        </a-form-model-item>

        <a-form-model-item label="售后备注" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-textarea v-model="form.salesRemarks" placeholder="请输入售后备注，方便客服更好的服务您的客户" />
          </div>
        </a-form-model-item>

        <!-- 开通栏目 新增-->
        <div class="lm-box">
          <div style="display:flex;align-items: center;">
            <span style="color:#000;margin-right: 10px;">主开通栏目</span>
            <a-button @click="resetMenu" v-if="faIndex == 0">恢复默认</a-button>
            <a-button @click="addCpMenu" v-if="faIndex == 2">添加</a-button>
          </div>
          <div>
            <div style="display: flex;margin-top: 15px;" v-for="(it, formIndex) in form.sfaCardCouponMenuList"
              :key="formIndex">
              <div style="position: relative;width: 90%;" v-if="faIndex == 0">
                <a-input v-model="it.menuName" style="width: 90%" disabled />
                <a-icon type="question-circle" style="right:4px;" class="question-icon" @click="openAlert('储值卡栏目')" />
              </div>
              <div style="position: relative;width: 90%;" v-if="faIndex == 1">
                <a-select v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in thArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:4px;" class="question-icon" @click="openAlert('提货券栏目')" />
              </div>
              <div style="position: relative;width: 90%;" v-if="faIndex == 2">
                <a-select v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in cpArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:2px;" class="question-icon" @click="openAlert('次票栏目')" />
              </div>
              <div v-if="it.menu == 5 && faIndex == 2" style="width:80%;">
                <a-input v-model="it.incrementAmount" placeholder="增值服务抵扣金额" />
              </div>

              <div style="position: relative;width: 90%">
                <a-select v-model="it.menuShow" style="width: 90%" placeholder="是否显示按钮">
                  <a-select-option :value="1" label="显示按钮">显示按钮</a-select-option>
                  <a-select-option :value="2" label="不显示按钮">不显示按钮</a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:2px;" class="question-icon" @click="openAlert('显示按钮')" />
              </div>
              <div style="position: relative;width: 100%">
                <a-input v-model="it.remarks" placeholder="请输入特殊备注" />
              </div>
              <div v-if="faIndex == 0">
                <a-button style="margin-left: 18px;height: 28px;" @click="dellm(1, formIndex)">删除</a-button>
              </div>
              <div v-if="faIndex == 2">
                <a-button style="margin-left: 18px;height: 28px;" @click="dellm(1, formIndex)">删除</a-button>
              </div>
            </div>
          </div>
        </div>


      </a-form-model>

      <div style="display: flex;justify-content: center;">
        <a-button type="primary" @click="handleOk" style="margin-right: 10px;height: 28px;margin-top: 24px;">保存
        </a-button>
      </div>
    </a-modal>

    <!-- 修改 -->
    <a-modal title="编辑" :visible="EditVisible" :confirm-loading="confirmLoading" :maskClosable="false" @ok="handleEdit"
      :footer="null" @cancel="cancelChange" :width="780" :z-index="100">
      <a-form-model ref="ruleEditForm" :model="editObj" :rules="editRules" :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item ref="distributorCode" prop="distributorCode" label="分销商" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="editObj.distributorCode" style="width: 100%" placeholder="请选择分销商"
              option-label-prop="label" @change="codeChange">
              <a-select-option value="20220601001" label="优加惠品">
                优加惠品
              </a-select-option>
              <a-select-option value="20221212001" label="好乐滋">
                好乐滋
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('分销商')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="giftId" prop="giftId" label="卡券产品" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="editObj.giftId" style="width: 100%" placeholder="请选择卡券产品" :default-value="null">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in giftList" :key="index">
                {{ item.name }}<span v-if="item.code">({{ item.code }})</span>
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('卡券产品')" />
          </div>
        </a-form-model-item>
        <!-- 提货券档位 -->
        <a-form-model-item required ref="gearPosition" prop="gearPosition" label="档位" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }" v-if="faType == 4">
          <div style="position: relative;">
            <a-select v-model="editObj.gearPosition" @change="dwChange" style="width: 100%" placeholder="请选择档位">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in dwArr" :key="index">
                {{ item.name }}档
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('提货券档位')" />
          </div>
        </a-form-model-item>
        <!-- 次票档位 -->
        <a-form-model-item required ref="rechargeGearPosition" prop="rechargeGearPosition" label="档位"
          :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }" v-if="faType == 1">
          <div style="position: relative;">
            <a-select v-model="editObj.rechargeGearPosition" @change="cpdwChange" style="width: 100%"
              placeholder="请选择档位">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in cpdwArr" :key="index">
                {{ item.name }}档
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('次票档位')" />
          </div>
        </a-form-model-item>
        <a-form-model-item v-if="faIndex == 1 || faType == 4" required prop="type" label="产品类型" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="editObj.type" style="width: 100%" placeholder="产品类型">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in prodArr" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('产品类型')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="giftName" prop="giftName" label="礼品卡名称" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="editObj.giftName" />
            <a-icon type="question-circle" class="question-icon" @click="openAlert('礼品卡名称')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="category" prop="category" label="卡/券" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="editObj.category" style="width: 100%" placeholder="请选择卡/券">
              <a-select-option :value="1" label="卡">
                卡
              </a-select-option>
              <a-select-option :value="2" label="券">
                券
              </a-select-option>
            </a-select>
          </div>
        </a-form-model-item>
        <a-form-model-item ref="customized" prop="customized" label="是否定制卡" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="editObj.customized" style="width: 100%" placeholder="请选择是否定制卡" option-label-prop="label">
              <a-select-option :value="1" label="是">
                是
              </a-select-option>
              <a-select-option :value="0" label="否">
                否
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('定制卡样')" />
          </div>
        </a-form-model-item>

        <a-form-model-item v-if="editObj.customized == 1" ref="customerId" prop="customerId" label="客户名称"
          :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-select v-model="editObj.customerId" style="width: 100%" placeholder="请选择客户"
            :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
            show-search :default-value="null" option-label-prop="label">
            <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in customerData" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item ref="styleId" prop="styleId" label="线上卡券样式" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <!-- 编辑 -->
          <div style="position: relative; width: 200px;cursor: pointer;" v-if="type == 'edit' && EditVisible"
            @click="getCardStyle()">
            <div class="select-btn" v-if="!editObj.style">
              <p>选择</p>
            </div>
            <div class="select-btn" style="border: none;" @click="isSelectCard = true" v-else>
              <img :src="editObj.style.imagePath ? editObj.style.imagePath : editObj.style" alt="">
            </div>
            <a-icon type="question-circle" class="question-icon" @click.stop="openAlert('卡券样式')" />
          </div>
          <div style="position: relative; width: 200px;cursor: pointer;" v-else @click="getCardStyle()">
            <div class="select-btn" style="border: none;" @click="isSelectCard = true">
              <img :src="editObj.style" alt="">
            </div>
            <a-icon type="question-circle" class="question-icon" @click.stop="openAlert('卡券样式')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="mediaType" prop="mediaType" label="介质类型" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="editObj.mediaType" style="width: 100%" placeholder="请选择介质类型">
              <a-select-option :value="1" label="电子卡">
                电子卡
              </a-select-option>
              <a-select-option :value="2" label="实体卡">
                实体卡
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('介质类型')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="number" prop="number" label="售卡数量" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="editObj.number" placeholder="请输入售卡数量" />
            <span class="ipt-text">张</span>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('数量')" />
          </div>
        </a-form-model-item>

        <a-form-model-item v-if="faType == 1 || faType == 3" ref="amountRecharge" prop="amountRecharge" label="单张充值"
          :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="editObj.amountRecharge" placeholder="请输入单张充值" />
            <span class="ipt-text">元</span>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('充值金额')" />
          </div>
        </a-form-model-item>

        <a-form-model-item v-if="faType == 4" ref="amountRecharge" prop="amountRecharge" label="档位市场价值"
          :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="editObj.amountRecharge" placeholder="请输入档位市场价值" />
            <span class="ipt-text">元</span>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('充值金额')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="amount" prop="amount" label="单张实收" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input disabled v-model="editObj.amount" placeholder="请输入单张实收金额" />
            <span class="ipt-text">元</span>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('实收金额')" />
          </div>
        </a-form-model-item>
        <a-form-model-item label="折扣" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            {{ editObj.discount }}
            <a-icon type="question-circle" class="question-icon" @click="openAlert('折扣')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="customized" prop="customized" label="发票" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="editObj.invoice" style="width: 100%" placeholder="请选择是否需要发票" option-label-prop="label">
              <a-select-option :value="1" label="开票">开票</a-select-option>
              <a-select-option :value="2" label="不开票">不开票</a-select-option>
              <a-select-option :value="3" label="未知">未知</a-select-option>
            </a-select>
          </div>
        </a-form-model-item>

        <a-form-model-item required label="有效期" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-range-picker @change="dateEditChange" :value="editDateValue"
              :ranges="{ '1年': [moment(), moment().add(1, 'years')], '2年': [moment(), moment().add(2, 'years')], '3年': [moment(), moment().add(3, 'years')] }"
              :default-value="[formatDate(editObj.startTime), formatDate(editObj.endTime)]"
              :placeholder="['有效期开始时间', '有效期结束时间']" style="width:100%;" />
            <a-icon type="question-circle" class="question-icon" @click="openAlert('有效期')" />
          </div>
        </a-form-model-item>

        <a-form-model-item required ref="isDelay" prop="isDelay" label="是否允许延期" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="editObj.isDelay" style="width: 100%" placeholder="请选择是否允许延期" option-label-prop="label">
              <a-select-option :value="1" label="允许延期">允许延期</a-select-option>
              <a-select-option :value="2" label="不允许延期">不允许延期</a-select-option>
              <a-select-option :value="3" label="未知">未知</a-select-option>
            </a-select>
          </div>
        </a-form-model-item>

        <a-form-model-item label="售后备注" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-textarea v-model="editObj.salesRemarks" placeholder="请输入售后备注，方便客服更好的服务您的客户" />
          </div>
        </a-form-model-item>

        <!-- 开通栏目 编辑-->
        <div class="lm-box">
          <div style="display:flex;align-items: center;">
            <span style="color:#000">主开通栏目</span>
          </div>
          <!-- 编辑-修改 -->
          <div v-if="type == 'edit' && !this.$route.query.pageType">
            <div style="display: flex;margin-top: 15px;" v-for="(it, index) in editObj.sfaCardCouponMenuList"
              :key="index">
              <div style="position: relative;width: 90%;" v-if="faType == 3">
                <a-select v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in lmArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:4px;" class="question-icon" @click="openAlert('储值卡栏目')" />
              </div>
              <div style="position: relative;width: 90%;" v-if="faType == 4">
                <a-select v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in thArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:4px;" class="question-icon" @click="openAlert('提货券栏目')" />
              </div>
              <div style="position: relative;width: 90%;" v-if="faType == 1">
                <a-select v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in cpArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:0px;" class="question-icon" @click="openAlert('次票栏目')" />
              </div>
              <div v-if="it.menu == 5 && faIndex == 2" style="width:80%;">
                <a-input v-model="it.incrementAmount" placeholder="增值服务抵扣金额" />
              </div>
              <!--    提货券是否开通栏目按钮          -->
              <div v-if="faIndex == 1 || faType == 4" style="position: relative;width: 90%">
                <a-input value="显示按钮" disabled style="width: 90%" />
                <a-icon type="question-circle" style="right:4px;" class="question-icon" @click="openAlert('显示按钮')" />
              </div>
              <!--   其他栏目-->
              <div v-else style="position: relative;width: 90%">
                <a-select v-model="it.menuShow" style="width: 90%" placeholder="是否显示按钮">
                  <a-select-option :value="1" label="显示按钮">显示按钮</a-select-option>
                  <a-select-option :value="2" label="不显示按钮">不显示按钮</a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:0px;" class="question-icon" @click="openAlert('显示按钮')" />
              </div>



              <div style="position: relative;width: 100%">
                <a-input v-model="it.remarks" placeholder="请输入特殊备注" />
              </div>
              <!--faType  1次票 4提货 3储值 -->
              <div v-if="faType == 3 || faType == 1">
                <a-button type="primary" v-if="index == 0" style="margin-left: 18px;height: 28px;" @click="addlm(0)">添加
                </a-button>
                <a-button v-if="index > 0" style="margin-left: 18px;height: 28px;" @click="dellm(0, index)">删除
                </a-button>
              </div>
            </div>
          </div>

          <!-- 编辑-新增 -->
          <div v-if="type == 'add'">
            <div style="display: flex;margin-top: 15px;" v-for="(it, index) in editObj.sfaCardCouponMenuList"
              :key="index">
              <div style="position: relative;width: 90%;" v-if="faType == 3">
                <a-select v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in lmArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:4px;" class="question-icon" @click="openAlert('储值卡栏目')" />
              </div>
              <div style="position: relative;width: 90%;" v-if="faType == 4">
                <a-select v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in thArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:4px;" class="question-icon" @click="openAlert('提货券栏目')" />
              </div>
              <div style="position: relative;width: 90%;" v-if="faType == 1">
                <a-select v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in cpArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:0px;" class="question-icon" @click="openAlert('次票栏目')" />
              </div>
              <div v-if="it.menu == 5 && faIndex == 2" style="width:80%;">
                <a-input v-model="it.incrementAmount" placeholder="增值服务抵扣金额" />
              </div>
              <!--    提货券是否开通栏目按钮          -->
              <div v-if="faIndex == 1" style="position: relative;width: 90%">
                <a-input value="显示按钮" disabled style="width: 90%" />
                <a-icon type="question-circle" style="right:0px;" class="question-icon" @click="openAlert('显示按钮')" />
              </div>
              <!--   其他栏目-->
              <div v-else style="position: relative;width: 90%">
                <a-select v-model="it.menuShow" style="width: 90%" placeholder="是否显示按钮">
                  <a-select-option :value="1" label="显示按钮">显示按钮</a-select-option>
                  <a-select-option :value="2" label="不显示按钮">不显示按钮</a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:4px;" class="question-icon" @click="openAlert('显示按钮')" />
              </div>

              <div style="position: relative;width: 100%">
                <a-input v-model="it.remarks" placeholder="请输入特殊备注" />
              </div>

              <!-- 储值卡 -->
              <div v-if="faIndex == 0">
                <a-button type="primary" v-if="index == 0" style="margin-left: 18px;height: 28px;" @click="addlm(0)">添加
                </a-button>
                <a-button v-if="index > 0" style="margin-left: 18px;height: 28px;" @click="dellm(0, index)">删除
                </a-button>
              </div>
              <!-- 次票 -->
              <div v-if="faIndex == 2">
                <a-button type="primary" v-if="index == 0" style="margin-left: 18px;height: 28px;" @click="addlm(0)">添加
                </a-button>
                <a-button v-if="index > 0" style="margin-left: 18px;height: 28px;" @click="dellm(0, index)">删除
                </a-button>
              </div>
            </div>
          </div>

          <!-- 编辑-复制 -->
          <div v-if="this.$route.query.pageType">
            <div style="display: flex;margin-top: 15px;" v-for="(it, index) in editObj.sfaCardCouponMenuList"
              :key="index">
              <div style="position: relative;width: 90%;" v-if="faType == 3">
                <a-select v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in lmArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:4px;" class="question-icon" @click="openAlert('储值卡栏目')" />
              </div>
              <div style="position: relative;width: 90%;" v-if="faType == 4">
                <a-select v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in thArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:4px;" class="question-icon" @click="openAlert('提货券栏目')" />
              </div>
              <div style="position: relative;width: 90%;" v-if="faType == 1">
                <a-select v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in cpArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:0px;" class="question-icon" @click="openAlert('次票栏目')" />
              </div>
              <div v-if="it.menu == 5 && faIndex == 2" style="width:80%;">
                <a-input v-model="it.incrementAmount" placeholder="增值服务抵扣金额" />
              </div>
              <div style="position: relative;width: 90%">
                <a-select v-model="it.menuShow" style="width: 90%" placeholder="是否显示按钮">
                  <a-select-option :value="1" label="显示按钮">显示按钮</a-select-option>
                  <a-select-option :value="2" label="不显示按钮">不显示按钮</a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:0px;" class="question-icon" @click="openAlert('显示按钮')" />
              </div>
              <div style="position: relative;width: 100%">
                <a-input v-model="it.remarks" placeholder="请输入特殊备注" />
              </div>
              <!--faType  1次票 4提货 3储值 -->

              <div v-if="faType == 3 || faType == 1">
                <a-button type="primary" v-if="index == 0" style="margin-left: 18px;height: 28px;" @click="addlm(0)">添加
                </a-button>
                <a-button v-if="index > 0" style="margin-left: 18px;height: 28px;" @click="dellm(0, index)">删除
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </a-form-model>

      <div style="display: flex;justify-content: center;">
        <a-button type="primary" @click="handleEdit" style="margin-right: 10px;height: 28px;margin-top: 24px;">保存
        </a-button>
      </div>
    </a-modal>

    <!-- 详情 -->
    <a-modal title="详情" :visible="detailsVisible" :confirm-loading="confirmLoading" :maskClosable="false" :footer="null"
      @cancel="cancelChange" :width="750">
      <a-form-model ref="ruleForm" :model="editObj" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item ref="distributorCode" prop="distributorCode" label="分销商" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="editObj.distributorName" disabled />
            <a-icon type="question-circle" class="question-icon" @click="openAlert('分销商')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="giftId" prop="giftId" label="卡券产品" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input :value="editObj.giftNam" disabled />
            <a-icon type="question-circle" class="question-icon" @click="openAlert('卡券产品')" />
          </div>
        </a-form-model-item>

        <!-- 提货券档位 -->
        <a-form-model-item ref="gearPosition" prop="gearPosition" label="档位" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }" v-if="faIndex == 1">
          <div style="position: relative;">
            <a-select v-model="editObj.gearPosition" disabled style="width: 100%" placeholder="请选择档位">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in dwArr" :key="index">
                {{ item.name }}档
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('提货券档位')" />
          </div>
        </a-form-model-item>
        <!-- 次票档位 -->
        <a-form-model-item ref="rechargeGearPosition" prop="rechargeGearPosition" label="档位" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }" v-if="faIndex == 2">
          <div style="position: relative;">
            <a-select v-model="editObj.rechargeGearPosition" disabled style="width: 100%" placeholder="请选择档位">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in cpdwArr" :key="index">
                {{ item.name }}档
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('次票档位')" />
          </div>
        </a-form-model-item>

        <a-form-model-item v-if="faIndex == 1" prop="type" label="产品类型" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="editObj.type" disabled style="width: 100%" placeholder="产品类型">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in prodArr" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('产品类型')" />
          </div>
        </a-form-model-item>
        <a-form-model-item ref="giftName" prop="giftName" label="礼品卡名称" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="editObj.giftName" disabled />
            <a-icon type="question-circle" class="question-icon" @click="openAlert('礼品卡名称')" />
          </div>
        </a-form-model-item>
        <a-form-model-item ref="category" prop="category" label="卡/券" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-if="editObj.category == 1" value="卡" disabled />
            <a-input v-if="editObj.category == 2" value="券" disabled />
            <a-icon type="question-circle" class="question-icon" @click="openAlert('礼品卡名称')" />
          </div>
        </a-form-model-item>
        <a-form-model-item ref="customized" prop="customized" label="是否定制卡" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-if="editObj.customized == 1" value="是" disabled />
            <a-input v-if="editObj.customized == 0" value="否" disabled />
            <a-icon type="question-circle" class="question-icon" @click="openAlert('定制卡样')" />
          </div>
        </a-form-model-item>
        <a-form-model-item v-if="editObj.customized == 1" ref="customerId" prop="customerId" label="客户名称"
          :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select v-model="editObj.customerId" style="width: 100%" disabled option-label-prop="label">
              <a-select-option disabled :value="item.id" :label="item.name" v-for="(item, index) in customerData"
                :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <!-- <a-input v-model="editObj.customerId" disabled /> -->
          </div>
        </a-form-model-item>
        <a-form-model-item ref="styleId" prop="styleId" label="线上卡券样式" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative; width: 200px;cursor: pointer;" v-if="editObj.style">
            <img :src="editObj.style.imagePath" style="position: relative; width: 200px;cursor: pointer;" />
            <a-icon type="question-circle" class="question-icon" @click.stop="openAlert('卡券样式')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="mediaType" prop="mediaType" label="介质类型" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="editObj.mediaTypeName" disabled />
            <a-icon type="question-circle" class="question-icon" @click="openAlert('介质类型')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="number" prop="number" label="售卡数量" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="editObj.number" disabled />
            <span class="ipt-text">张</span>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('数量')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="amountRecharge" prop="amountRecharge" label="档位市场价值" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="editObj.amountRecharge" disabled placeholder="请输入档位市场价值" />
            <span class="ipt-text">元</span>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('充值金额')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="amount" prop="amount" label="单张实收" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input v-model="editObj.amount" disabled placeholder="请输入单张实收金额" />
            <span class="ipt-text">元</span>
            <a-icon type="question-circle" class="question-icon" @click="openAlert('实收金额')" />
          </div>
        </a-form-model-item>
        <a-form-model-item label="折扣" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input disabled :value="editObj.discount" />
            <a-icon type="question-circle" class="question-icon" @click="openAlert('折扣')" />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="customized" prop="customized" label="发票" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-input disabled value="开票" v-if="editObj.invoice == 1" />
            <a-input disabled value="不开票" v-if="editObj.invoice == 2" />
            <a-input disabled value="未知" v-if="editObj.invoice == 3" />
          </div>
        </a-form-model-item>

        <a-form-model-item required label="有效期" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;" v-if="editObj.startTime">
            {{ formatDate(editObj.startTime) }} ~ {{ formatDate(editObj.endTime) }}
            <a-icon type="question-circle" class="question-icon" @click="openAlert('有效期')" />
          </div>
        </a-form-model-item>

        <a-form-model-item required ref="isDelay" prop="isDelay" label="是否允许延期" :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-select disabled v-model="editObj.isDelay" style="width: 100%" placeholder="请选择是否允许延期"
              option-label-prop="label">
              <a-select-option :value="1" label="允许延期">允许延期</a-select-option>
              <a-select-option :value="2" label="不允许延期">不允许延期</a-select-option>
              <a-select-option :value="3" label="未知">未知</a-select-option>
            </a-select>
          </div>
        </a-form-model-item>

        <a-form-model-item label="售后备注" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="position: relative;">
            <a-textarea disabled v-model="editObj.salesRemarks" placeholder="请输入售后备注，方便客服更好的服务您的客户" />
          </div>
        </a-form-model-item>

        <!-- 开通栏目 详情-->
        <div class="lm-box">
          <div style="display:flex;align-items: center;">
            <span style="color:#000">主开通栏目</span>
          </div>
          <div>
            <div style="display: flex;margin-top: 15px;" v-for="(it, index) in editObj.sfaCardCouponMenuList"
              :key="index">
              <!-- 提货 -->
              <div style="position: relative;width: 90%;" v-if="faType == 4">
                <a-select disabled v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in thArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:4px;" class="question-icon" @click="openAlert('储值卡栏目')" />
              </div>
              <!-- 储值、次票 -->
              <div style="position: relative;width: 90%;" v-if="faType != 4">
                <a-select disabled v-model="it.menu" style="width: 90%" placeholder="选择栏目">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in lmArr" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:0px;" class="question-icon" @click="openAlert('储值卡栏目')" />
              </div>
              <div v-if="it.menu == 5 && faType == 1" style="width:80%;">
                <a-input disabled v-model="it.incrementAmount" placeholder="增值服务抵扣金额" />
              </div>
              <div style="position: relative;width: 90%">
                <a-select disabled v-model="it.menuShow" style="width: 90%" placeholder="是否显示按钮">
                  <a-select-option :value="1" label="显示按钮">显示按钮</a-select-option>
                  <a-select-option :value="2" label="不显示按钮">不显示按钮</a-select-option>
                </a-select>
                <a-icon type="question-circle" style="right:0px;" class="question-icon" @click="openAlert('显示按钮')" />
              </div>
              <div style="position: relative;width: 100%">
                <a-input disabled v-model="it.remarks" placeholder="请输入特殊备注" />
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
    </a-modal>

    <!-- 选择卡样式 -->
    <div class="sel-box">
      <a-modal title="选择卡样式" :visible="isSelectCard" :width="1200" :footer="null" @cancel="isSelectCard = false;"
        :z-index="999">
        <a-radio-group style="display: flex;width: 100%;flex-wrap: wrap;">
          <a-radio v-for="item in cardStyleArr" :key="item" :value="item.id" @click="selectedCardStyle(item)"
            style="width:23%;margin-bottom: 10px;">
            <img :src="item.imagePath" style="width: 90%;" alt="">
          </a-radio>
        </a-radio-group>
      </a-modal>
    </div>

    <!-- 提示 -->
    <div class="sel-box">
      <a-modal title="提示" :visible="isAlert" :width="600" :footer="null" @cancel="isAlert = false;">
        <p>{{ alContent }}</p>
      </a-modal>
    </div>

    <div style="height: 40px;"></div>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import debounce from 'lodash/debounce';
const columns = [
  {
    title: '分销商',
    dataIndex: 'distributorCode',
    scopedSlots: { customRender: 'distributorCode' },
    align: 'center',
  },
  {
    title: '礼品卡名称',
    dataIndex: 'giftName',
    scopedSlots: { customRender: 'giftName' },
    align: 'center',

  },
  {
    title: '线上卡券样式',
    dataIndex: 'style',
    ellipsis: true,
    align: 'center',
    scopedSlots: { customRender: 'style' },
  },
  {
    title: '介质类型',
    dataIndex: 'mediaType',
    ellipsis: true,
    align: 'center',
    scopedSlots: { customRender: 'mediaType' },
  },
  {
    title: '数量',
    dataIndex: 'number',
    align: 'center',
  },
  {
    // title: '档位市场价值',
    dataIndex: 'amountRecharge',
    slots: { title: 'amountRecharge' },
    align: 'center',
  },
  {
    title: '单张实收',
    dataIndex: 'amount',
    align: 'center',
  },

  {
    title: '折扣',
    dataIndex: 'discount',
    scopedSlots: { customRender: 'discount' },
    ellipsis: true,
    align: 'center',
  },
  {
    title: '激活状态',
    dataIndex: 'actStatusName',
    align: 'center',
  },
  {
    title: '冻结状态',
    dataIndex: 'frozenStatusName',
    align: 'center',
  },
  {
    title: '出库状态',
    dataIndex: 'outStatusName',
    align: 'center',
  },
  {
    title: '出库批次号',
    dataIndex: 'outBatchCode',
    align: 'center',
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    fixed: 'right',
    width: 120
  }
]
export default {
  name: 'QueryList',
  inject: ['reload'],
  props: {
    isBackShow: {
      type: Boolean,
      default: true
    },
    detailsArr: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: 'add'
    },
    // 合计实际收款
    totalAmount: {
      type: Number,
      default: 0
    },
    // 合计开票金额
    invoiceAmount: {
      type: Number,
      defalut: 0
    }
  },
  data() {
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 900);
    return {
      dateValue: null,
      editDateValue: null,
      alContent: "",
      dateFormat: "YYYY-MM-DD",
      isDetails: false,//是否详情
      isSelectCard: false, //选择卡样弹窗
      cardStyleArr: [],
      iptmd: 4,
      iptsm: 22,
      moment,
      columns: columns,
      // 筛选
      queryData: {
        page: 0,
        size: 10,
        name: '',//方案名称
        minProductBudget: '',
        maxProductBudget: '',//产品预算
        minProductPrice: '', //产品价格
        maxProductPrice: '',
        customerIdList: [],//选择客户
        dateMax: '',
        dateMin: '',
      },
      // 创建表单
      form: {
        // distributorCode:'',//分销商code
        // giftId:'',//卡券产品id
        // giftName:'',//礼品卡名称
        // customized:false,//是否定制卡
        styleId: '',//卡券样式ID
        // mediaType:'',//介质类型
        // number:'',//售卡数量
        amountRecharge: '',//充值金额
        amount: 0,//单张实收
        discount: 0,//折扣
        // startTime:'',//开始时间
        // endTime:'',//结束时间
        sfaCardCouponMenuList: [{}]
      },
      // { name: '景点门票', id: 9 }
      lmArr: [{ name: '商城', id: 1 }, { name: '电影', id: 2 }, { name: '蛋糕', id: 3 }, { name: '生活', id: 5 }, { name: '储值卡购买礼包商品', id: 6 }, { name: '演出', id: 7 }, { name: '线下门店', id: 8 }, { name: '图书', id: 9 }, { name: '景点门票', id: 10 }],
      cpArr: [{ name: '电影', id: 2 }, { name: '生活', id: 5 }],
      thArr: [{ name: '礼包兑换', id: 4 }],
      dwArr: [{ name: 118, id: 1 }, { name: 168, id: 10 }, { name: 238, id: 2 }, { name: 358, id: 3 }, { name: 468, id: 4 }, { name: 588, id: 5 }, { name: 718, id: 6 }, { name: 938, id: 7 }, { name: 1188, id: 8 }, { name: "定制", id: 9 }],
      zjArr: [{}],
      prodArr: [{ name: '常规挡', id: 1 }, {
        name: '定制含等额现金（京东卡/加油卡等）',
        id: 2
      }, { name: '定制不含等额现金（京东卡/加油卡等）', id: 3 }],
      cpdwArr: [{ name: '60', id: 1 }, { name: '70', id: 2 }, { name: '80', id: 3 }, { name: '定制', id: 4 }],
      selectedCardItem: {},
      // 新建方案
      visible: false,
      confirmLoading: false,
      // 修改方案
      EditVisible: false,
      // 新建方案弹窗
      labelCol: { span: 8 },
      wrapperCol: { span: 24 },
      rules: {
        distributorCode: [{ required: true, message: '请选择分销商', trigger: 'change' }],
        giftId: [{ required: true, message: '请选择卡券产品', trigger: 'change' }],
        gearPosition: [{ required: true, message: '请选择档位', trigger: 'change' }],
        giftName: [{ required: true, message: '请输入礼品卡名称', trigger: 'blur' }],
        category: [{ required: true, message: '请选择卡/券', trigger: 'change' }],
        customized: [{ required: true, message: '请选择是否定制卡', trigger: 'change' }],
        isDelay: [{ required: true, message: '请选择是否允许延期', trigger: 'change' }],
        mediaType: [{ required: true, message: '请选择介质类型', trigger: 'change' }],
        number: [{ required: true, message: '请输入售卡数量', trigger: 'blur' }],
        amountRecharge: [{ required: true, message: '请输入充值金额', trigger: 'blur' }],
        // amount: [{required: true, message: '请输入单张实收', trigger: 'blur'}],
        startTime: [{ required: true, message: '请选择日期', trigger: 'change' }],
        // type: [{required: true, message: '请选择产品类型', trigger: 'change'}],
      },
      editRules: {
        distributorCode: [{ required: true, message: '请选择分销商', trigger: 'change' }],
        giftId: [{ required: true, message: '请选择卡券产品', trigger: 'change' }],
        gearPosition: [{ required: true, message: '请选择档位', trigger: 'change' }],
        giftName: [{ required: true, message: '请输入礼品卡名称', trigger: 'blur' }],
        category: [{ required: true, message: '请选择卡/券', trigger: 'change' }],
        customized: [{ required: true, message: '请选择是否定制卡', trigger: 'change' }],
        isDelay: [{ required: true, message: '请选择是否允许延期', trigger: 'change' }],
        mediaType: [{ required: true, message: '请选择介质类型', trigger: 'change' }],
        number: [{ required: true, message: '请输入售卡数量', trigger: 'blur' }],
        amountRecharge: [{ required: true, message: '请输入充值金额', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入单张实收', trigger: 'blur' }],
        startTime: [{ required: true, message: '请选择日期', trigger: 'change' }],
        // type: [{required: true, message: '请选择产品类型', trigger: 'change'}],
      },
      editObj: {},
      giftList: [],

      editIndex: 0,//当前编辑下标
      sfaSaleDocketProductList: [{
        remarks: "",
        printRemarks: "",
        type: 3,
        sfaCardCouponList: [],
      }, {
        remarks: "",
        printRemarks: "",
        type: 4,
        sfaCardCouponList: [],
      }, {
        remarks: "",
        printRemarks: "",
        type: 1,
        sfaCardCouponList: [],
      }],
      detailsVisible: false,
      faIndex: 0,
      faStyleCard1: {},
      faStyleCard2: {},
      faStyleCard3: {},
      isAlert: false,
      faType: 0,
      customerData: [],

      cardArray:[],
      quanArray:[],
      cpArray:[],
      lmMenu:[
        {"menu":1,"menuShow":1,"menuName":"商城","remarks":""},
        {"menu":2,"menuShow":1,"menuName":"电影","remarks":""},
        {"menu":3,"menuShow":1,"menuName":"蛋糕","remarks":""},
        {"menu":5,"menuShow":1,"menuName":"生活","remarks":""},
        {"menu":6,"menuShow":1,"menuName":"储值卡购买礼包商品","remarks":""},
        {"menu":7,"menuShow":1,"menuName":"演出","remarks":""},
        {"menu":8,"menuShow":1,"menuName":"线下门店","remarks":""},
        {"menu":9,"menuShow":1,"menuName":"图书","remarks":""},
        {"menu":10,"menuShow":1,"menuName":"景点门票","remarks":""},
      ],
      thMenu: [{ "menu": 4, "menuShow": 1, "menuName": "礼包兑换", "remarks": "" }],
      cpMenu: [{ "menu": 2, "menuShow": 1, "menuName": "电影", "remarks": "" }, { "menu": 5, "menuShow": 1, "menuName": "生活", "remarks": "", incrementAmount: "" }],
      faInvoiceAmount: 0
    }
  },
  mounted() {
    this.getInitData();

    //  客户经理
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
      this.users = res.data.data;
    })
    // 卡券产品
    this.getGiftList('20220601001')
  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  watch: {
    $route() {
      if (this.type == 'add') {
        this.sfaSaleDocketProductList = [{
          remarks: "",
          printRemarks: "",
          type: 3,
          sfaCardCouponList: [],
        }, {
          remarks: "",
          printRemarks: "",
          type: 4,
          sfaCardCouponList: [],
        }, {
          remarks: "",
          printRemarks: "",
          type: 1,
          sfaCardCouponList: [],
        }]
      }
    },
    invoiceAmount: {
      handler: function (newVal) {
        this.faInvoiceAmount = newVal;
      },
    },
    // 平均折扣发生变化
    totalAmount: {
      handler() {
        this.calcDiscount()
      }
    }

  },
  methods: {
    fetchUser(value) {
      console.log(value, 'value')
      if (!value) {
        return false;
      }
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerData = [];
      this.fetching = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/listByCustomerName', 'post', { page: 0, size: 10, name: value }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerData = res.data.data.data;
        this.fetching = false;
      }).catch(error => {
        console.error('Error fetching user:', error);
      });
    },
    handleSearchChange(searchVal) {
      console.log(searchVal,'searchVal')
      this.form.customerId = searchVal;
      Object.assign(this, {
        searchVal,
        fetching: false,
      });
    },
    // 初始化数据
    getInitData() {
      // 客户列表
      // request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/listByCustomerName', 'post',{page:0,size:10}).then(res => {
      //   this.customerData = res.data.data
      // })
      // 接收父组件传来的数据 排序
      setTimeout(() => {
        var order = [3, 4, 1];
        this.detailsArr.sort((a, b) => {
          const typeA = a.type;
          const typeB = b.type;
          const indexA = order.indexOf(typeA);
          const indexB = order.indexOf(typeB);
          return indexA - indexB;
        });
        if (this.type == 'details') {
          this.sfaSaleDocketProductList = this.detailsArr
          this.isDetails = true //是否是详情
        }
        if (this.type == 'edit') {
          this.sfaSaleDocketProductList = this.detailsArr;
          console.log(this.sfaSaleDocketProductList, 'this.sfaSaleDocketProductList')
        }
      }, 1000)
    },
    // 卡券产品
    getGiftList(id, faType) {
      this.cardArray = []
      this.quanArray = []
      this.cpArray = []
      this.giftList = []
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/card/gift/type/list/gift/' + id, 'get').then(res => {
        let data = res.data.data;
        // 根据卡、券、次票区分
        if (faType) {
          data.filter(item => {
            item.giftList.some(gift => {
              if (faType == 3) {
                if (!gift.name.includes('提货') && !gift.name.includes('次票')) {
                  this.giftList.push(gift)
                }
              }
              if (faType == 4) {
                if (gift.name.includes('提货')) {
                  this.giftList.push(gift)
                }
              }
              if (faType == 1) {
                if (gift.name.includes('次票')) {
                  this.giftList.push(gift)
                }
              }
            })
          })
        } else {
          data.filter(item => {
            item.giftList.some(gift => {
              if (this.faIndex == 0) {
                if (!gift.name.includes('提货') && !gift.name.includes('次票')) {
                  this.giftList.push(gift)
                }
              }
              if (this.faIndex == 1) {
                if (gift.name.includes('提货')) {
                  this.giftList.push(gift)
                }
              }
              if (this.faIndex == 2) {
                if (gift.name.includes('次票')) {
                  this.giftList.push(gift)
                }
              }
            })
          })
        }
      })
    },
    // 关闭弹窗
    cancelChange() {
      this.visible = false;
      this.EditVisible = false;
      this.detailsVisible = false;
      this.form = {
        // distributorCode:'',//分销商code
        // giftId:'',//卡券产品id
        // giftName:'',//礼品卡名称
        // customized:false,//是否定制卡
        styleId: '',//卡券样式ID
        // mediaType:'',//介质类型
        // number:'',//售卡数量
        amountRecharge: '',//充值金额
        amount: '',//单张实收
        discount: 0,//折扣
        // startTime:'',//开始时间
        // endTime:'',//结束时间
        sfaCardCouponMenuList: [{}]
      }
      this.dateValue = ''
      this.editDateValue = ''
      this.faType = 0
      this.faIndex = 0
    },
    getFaIndex(e) {
      this.faType = e;
    },
    dwChange(e) {
      console.log(e,'eee')
      if(e == 1) {
        this.$set(this.form,'amountRecharge',this.dwArr[0].name)
      } else {
        if (e != 9 && e != 10) {
        this.$set(this.form,'amountRecharge',this.dwArr[e].name)
      }
      if(e == 10) {
        this.$set(this.form,'amountRecharge',this.dwArr[1].name)
      }
      }
      
    },
    cpdwChange(e) {
      if (e != 4) {
        this.form.amountRecharge = this.cpdwArr[e - 1].name
      }
    },
    openAlert(val) {
      this.isAlert = true;
      if (val == '分销商') {
        this.alContent = '当前卡券可在哪个小程序使用货堆宅配根据客户历史采购优加或好乐滋选择自行选择，新客户默认选择优加惠品'
        return false;
      }
      if (val == '卡券产品') {
        this.alContent = '如未找到售卖产品请查看分销商选择是否正确'
        return false;
      }
      if (val == '礼品卡名称') {
        this.alContent = '礼品卡名称会在用户端展示，如客户无特殊要求请使用默认卡名称'
        return false;
      }
      if (val == '定制卡样') {
        this.alContent = '单笔销售额30万以上免费定制，需提前2个工作日报备申请；30万以下按500元/图结算。'
        return false;
      }
      if (val == '卡券样式') {
        this.alContent = '卡样式会展示在用户端，如需定制卡样式请提前2个工作日联系李响上传'
        return false;
      }
      if (val == '介质类型') {
        this.alContent = '如客户要求绑定手机号、绑定账号等、卡号/卡密通过短信形式发送至客户手机号，请出电子卡券'
        return false;
      }
      if (val == '数量') {
        this.alContent = '根据面值区分卡数量，比如出30张500元，20张1000元则需填写两条记录'
        return false;
      }
      if (val == '充值金额') {
        this.alContent = '输入正常档位金额，比如118档则输入118即可，比如定制500档，每组商品不能低于550，则输入550'
        return false;
      }
      if (val == '实收金额') {
        this.alContent = '单张实收金额，请自行计算清楚，如遇到除不开情况可单独增加1行，出库数量为1张，剩余金额录入到单张中，如不会填写可联系财务部'
        return false;
      }
      if (val == '折扣') {
        this.alContent = '单张实收金额/单张充值'
        return false;
      }
      if (val == '有效期') {
        this.alContent = '有效开始时间：如未到开始时间则不允许用户绑定卡券，请根据实际情况填写；有效结束时间：有效期最多为3年'
        return false;
      }
      if (val == '显示按钮') {
        this.alContent = '按钮目的：客户同时购买电影卡、蛋糕卡，蛋糕卡可开通蛋糕和商城，但电影卡工会不允许开通商城栏目，但工会员工又需要在商城栏目使用电影卡消费；如何设置：蛋糕卡开通蛋糕显示、商城显示，电影卡开通电影显示、商城不显示，这时用户通过蛋糕卡进入商城后支付时可选择电影卡和蛋糕卡合并支付，但在电影卡列表电影卡不展示商城按钮。'
        return false;
      }
      if (val == '储值卡栏目') {
        this.alContent = '当前为平台主栏目，如有特殊需求可备注到当前栏目，财务出库时自动屏蔽'
        return false;
      }
      if (val == '次票栏目') {
        this.alContent = '在各栏目消费仅可使用一次，支持多张次票同时使用多张次票使用规则：购买1件商品，不允许使用多张次票，不够部分可使用微信补差购买2件商品，最多可使用2张次票抵扣，不够部分可使用微信补差以此类推'
        return false;
      }
      if (val == '提货券档位') {
        this.alContent = '118-1188为常规档，其他金额均属于定制档'
        return false;
      }
      if (val == '产品类型') {
        this.alContent = '请根据实际情况选择，常规档为公司通用档位，等额现金为京东卡、加油卡等'
        return false;
      }
      if (val == '次票充值金额') {
        this.alContent = '充值金额=影院消费金额或小程序线上消费金额'
        return false;
      }
    },
    discountPrice() {
      if (this.form.amount && this.form.amountRecharge) {
        this.form.discount = (this.form.amount / this.form.amountRecharge * 100).toFixed(2)
        return (this.form.amount / this.form.amountRecharge * 100).toFixed(2)
      } else {
        this.form.discount = 0
        return 0
      }

    },
    selectedCardStyle(item) {
      if (this.EditVisible) {
        // 编辑
        console.log(this.editObj, 'edit')
        if (this.type == 'edit' && !this.$route.query.pageType) {
          // this.editObj.style.imagePath = item.imagePath
          this.editObj.style = item.imagePath
        } else {
          this.editObj.style = item.imagePath
        }
        this.editObj.styleId = item.id
      } else {
        this.form.style = item.imagePath;
        if (this.faIndex == 0) {
          this.faStyleCard1 = item
        }
        if (this.faIndex == 1) {
          this.faStyleCard2 = item
        }
        if (this.faIndex == 2) {
          this.faStyleCard3 = item
        }
        this.selectedCardItem = item;
        this.form.styleId = item.id;
      }
      this.isSelectCard = false;
    },
    codeChange(e) {
      this.selectedCardItem = {}
      if (this.EditVisible) {
        this.editObj.style = {}
        this.editObj.styleId = ''
      }

      // this.form.giftId = "";
      delete this.form.giftId
      delete this.editObj.giftId
      this.editObj.style = null;
      // 卡券
      this.getGiftList(e)
    },
    selectProd(e) {
      this.form.giftName = e;
    },
    getCardStyle() {
      let distributorCode = ''
      if (this.visible) {
        distributorCode = this.form.distributorCode;
      }
      if (this.EditVisible) {
        distributorCode = this.editObj.distributorCode;
      }
      if (!distributorCode) {
        this.$message.error('请先选择分销商')
      } else {
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/card/style/list/' + distributorCode, 'get').then(res => {
          this.cardStyleArr = res.data.data;
          this.isSelectCard = true
        })
      }


    },
    // 选择日期
    dateChange(date, dateString) {
      const [start, end] = date;
      if (start && end) {
        const diffInYears = Math.abs(end.diff(start, 'years'));
        if (diffInYears > 3) {
          this.dateValue = [null, null];
          // 如果选择的日期范围超过3年，则将结束日期调整为开始日期加上三年
          this.$message.warning('最大选择日期范围为3年');
        } else {
          this.dateValue = [dateString[0].toString(), dateString[1].toString()];
          this.form.startTime = dateString[0].toString();
          this.form.endTime = dateString[1].toString();
        }
      }
    },
    dateEditChange(date, dateString) {
      const [start, end] = date;
      if (start && end) {
        const diffInYears = Math.abs(end.diff(start, 'years'));
        if (diffInYears > 3) {
          this.editDateValue = [null, null];
          // 如果选择的日期范围超过3年，则将结束日期调整为开始日期加上三年
          this.$message.warning('最大选择日期范围为3年');
        } else {
          this.editDateValue = [dateString[0].toString(), dateString[1].toString()];
          this.editObj.startTime = dateString[0].toString();
          this.editObj.endTime = dateString[1].toString();
        }
      }
      console.log(this.editObj, 'obj')
    },
    back() {
      this.$router.go(-1)
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    addlm(i) {
      if (i == 1) {
        this.form.sfaCardCouponMenuList.push({ amount: '' })
      } else {
        this.editObj.sfaCardCouponMenuList.push({ amount: '' })
      }
    },
    dellm(type, i) {
      if (type == 1) {
        if (this.form.sfaCardCouponMenuList.length == 1) {
          this.$message.error('最少保留一项！')
        } else {
          this.form.sfaCardCouponMenuList.splice(i, 1)
        }
      } else {
        if (this.editObj.sfaCardCouponMenuList.length == 1) {
          this.$message.error('最少保留一项！')
        } else {
          this.editObj.sfaCardCouponMenuList.splice(i, 1)
        }
      }
      this.$emit('handleObject', this.sfaSaleDocketProductList); // 通过$emit方法触发自定义事件，并传递对象
    },

    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    addBtn(faIndex, type) {
      // if(this.faInvoiceAmount == 0 && this.type == 'add') {
      //   this.$message.error('请先填写合计开票金额！')
      //   return false;
      // }
      // 1 次票 3 储值卡 4 礼品兑换卡
      this.visible = true;
      this.giftList = []
      this.faIndex = faIndex;
      this.form = {
        amountRecharge: 0,//充值金额
        amount: 0,//单张实收
        discount: 0,//折扣
        sfaCardCouponMenuList: [{}]
      }
      // lmArr: [{name: '商城', id: 1}, {name: '电影', id: 2}, {name: '蛋糕', id: 3}, {name: '生活', id: 5}, {name: '储值卡购买礼包商品', id: 6}, {name: '演出', id: 7}, {name: '线下门店', id: 8}],
      if (type == 1) {
        // this.form.sfaCardCouponMenuList = JSON.parse(JSON.stringify(this.cpMenu));
        this.form.sfaCardCouponMenuList = [{ "menu": 2, "menuShow": 1, "menuName": "电影", "remarks": "" }]

      }
      if (type == 3) {
        this.form.sfaCardCouponMenuList = JSON.parse(JSON.stringify(this.lmMenu));
      }
      if (type == 4) {
        this.form.sfaCardCouponMenuList = JSON.parse(JSON.stringify(this.thMenu));
      }

      // this.$refs.ruleForm.resetFields();
    },
    giftsBtn(faIndex, type) {
      // 1 次票 3 储值卡 4 礼品兑换卡
      this.visible = true;
      this.giftList = []
      this.faIndex = faIndex;
      // lmArr: [{name: '商城', id: 1}, {name: '电影', id: 2}, {name: '蛋糕', id: 3}, {name: '生活', id: 5}, {name: '储值卡购买礼包商品', id: 6}, {name: '演出', id: 7}, {name: '线下门店', id: 8}],
      if (type == 1) {
        // this.form.sfaCardCouponMenuList = JSON.parse(JSON.stringify(this.cpMenu));
        this.form.sfaCardCouponMenuList = [{ "menu": 2, "menuShow": 1, "menuName": "电影", "remarks": "" }]
      }
      if (type == 3) {
        this.form.sfaCardCouponMenuList = JSON.parse(JSON.stringify(this.lmMenu));
      }
      if (type == 4) {
        this.form.sfaCardCouponMenuList = JSON.parse(JSON.stringify(this.thMenu));
      }
      // sellType  1:售卖 2:赠送
      // this.form.sellType = 2;
      // 单张实收
      this.form.amount = 0;
    },
    resetMenu() {
      if (this.faIndex == 0) {
        this.form.sfaCardCouponMenuList = JSON.parse(JSON.stringify(this.lmMenu));
      }
      if (this.faIndex == 2) {
        this.form.sfaCardCouponMenuList = JSON.parse(JSON.stringify(this.cpMenu));
      }
    },
    addCpMenu() {
      this.form.sfaCardCouponMenuList.push({})
    },
    detailsChange(record, type) {
      this.faType = type;
      this.editObj = JSON.parse(JSON.stringify(record));
      this.detailsVisible = true;
    },
    // 编辑
    editChange(record, type) {
      this.faType = type;
      if (type == 1) {
        this.faIndex = 2
      }
      if (type == 3) {
        this.faIndex = 0
      }
      // 1次票 4提货 3储值
      if (type == 4) {
        this.faIndex = 1
      }
      this.editIndex = this.getIndex(record)
      this.editObj = JSON.parse(JSON.stringify(record));
      this.EditVisible = true;
      this.getGiftList(this.editObj.distributorCode, type)
    },
    getIndex(record) {
      return this.sfaSaleDocketProductList[this.faIndex].sfaCardCouponList.indexOf(record);
    },
    formatPhone(phone, type, id) {
      if (phone) {
        const phoneNumbers = phone.split(",");
        const formattedPhoneNumbers = phoneNumbers.map(number => {
          return { type: type, contactInformation: number.trim(), id: id };
        });
        return formattedPhoneNumbers;
      }
    },
    hasMenuShow(array, type) {
      if (type == 1) {
        return array.every(item => typeof item === 'object' && item !== null && 'menuShow' in item);
      } else {
        return array.every(item => typeof item === 'object' && item !== null && 'menu' in item);
      }
    },
    handleOk(e) {
      if (this.faIndex == 1 || this.faType == 4) {
        this.form.sfaCardCouponMenuList[0].menuShow = 1;
      }
      // 检查次票增值服务金额
      const hasError = this.form.sfaCardCouponMenuList.some(item => {
        if (item.menu === 5 && this.faIndex == 2) {
          if (!item.incrementAmount) {
            this.$message.error('请输入增值服务抵扣金额');
            return true; // 中止循环
          } else {
            if (item.incrementAmount - 0 > this.form.amountRecharge) {
              this.$message.error('增值服务抵扣金额不可大于单张充值金额！');
              return true; // 中止循环
            }
          }
        }
      });
      if (hasError) {
        return false;
      }
      // 校验每个栏目是否选择显示按钮
      if (this.form.sfaCardCouponMenuList.length > 0) {
        let isBo = this.hasMenuShow(this.form.sfaCardCouponMenuList, 1)
        let isMenu = this.hasMenuShow(this.form.sfaCardCouponMenuList, 2)
        if (!isMenu) {
          this.$message.warning('请选择栏目名称！')
          return false;
        }
        if (!isBo) {
          this.$message.warning('请选择栏目是否显示按钮！')
          return false;
        }
      }
      if (this.form.customized == 1 && !this.form.customerId) {
        this.$message.warning('请选择客户！')
        return false;
      }
      if (!this.form.sfaCardCouponMenuList[0].menu && this.form.sfaCardCouponMenuList.length <= 1) {
        this.$message.warning('请选择开通栏目！')
        return false;
      }
      if (!this.form.styleId) {
        this.$message.warning('请选择卡券样式！')
        return false;
      }
      if (!this.form.startTime || !this.form.endTime) {
        this.$message.warning('请选择有效期！')
        return false;
      }
      // if(this.form.amount == 'undefined') {
      //   this.$message.warning('请输入单张实收金额！')
      //   return false;
      // }
      if (!this.form.invoice) {
        this.$message.warning('请选择是否需要发票！')
        return false;
      }
      e.preventDefault()
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.sfaSaleDocketProductList[this.faIndex].sfaCardCouponList.push(this.form);
          this.calcDiscount()
          this.visible = false;
          // this.$emit('handleObject', this.sfaSaleDocketProductList); // 通过$emit方法触发自定义事件，并传递对象
          this.form = {
            amountRecharge: 0,//充值金额
            amount: 0,//单张实收
            discount: 0,//折扣
            sfaCardCouponMenuList: [{}]
          }
          this.dateValue = ""
          this.selectedCardItem = {}
        } else {
          return false;
        }
      });
    },

    // 计算折扣
    calcDiscount() {
      console.log(this.totalAmount, 'this.totalAmount ')
      // 判断卡券是否为空
      if (this.sfaSaleDocketProductList[0].sfaCardCouponList.length == 0 && this.sfaSaleDocketProductList[1].sfaCardCouponList.length == 0 && this.sfaSaleDocketProductList[2].sfaCardCouponList.length == 0) {
        this.$emit('handleDiscount', 0)
        this.$emit('handleObject', this.sfaSaleDocketProductList); // 通过$emit方法触发自定义事件，并传递对象
        return false;
      }
      // 计算卡券实充
      let totalAmountRecharge = 0; //充值总金额
      this.sfaSaleDocketProductList.forEach(item => {
        item.sfaCardCouponList.forEach(it => {
          if (it.amountRecharge > 0) { // 只有在 amountRecharge > 0 时才加到总金额中
            totalAmountRecharge += it.amountRecharge * it.number;
          }
        })
      })
      // 如果 totalAmountRecharge 仍然为 0，折扣计算是无意义的，直接返回
      if (totalAmountRecharge === 0) {
        this.sfaSaleDocketProductList.forEach(item => {
          item.sfaCardCouponList.forEach(it => {
            it.amount = 0;
            it.discount = 0;
          });
        });

        this.$emit('handleDiscount', 0);
        this.$emit('handleObject', this.sfaSaleDocketProductList);
        return;
      }
      // 折扣
      let calcAverageDiscount = 0;
      // 合计实际收款
      console.log(this.totalAmount, 'this.totalAmount ')
      if (this.totalAmount == 0) {
        this.averageDiscount = totalAmountRecharge.toFixed(4)
        calcAverageDiscount = totalAmountRecharge
        // 更新父组件折扣
        this.$emit('handleDiscount', this.averageDiscount)
        this.$emit('handleObject', this.sfaSaleDocketProductList); // 通过$emit方法触发自定义事件，并传递对象
      } else {
        // 折扣 = 实收 / 充值总金额
        this.averageDiscount = (this.totalAmount / totalAmountRecharge).toFixed(4)
        calcAverageDiscount = (this.totalAmount / totalAmountRecharge).toFixed(4)
        this.$emit('handleDiscount', this.averageDiscount)
        this.$emit('handleObject', this.sfaSaleDocketProductList); // 通过$emit方法触发自定义事件，并传递对象
      }
      console.log(calcAverageDiscount, 'calcAverageDiscount')
      this.sfaSaleDocketProductList.forEach(item => {
        item.sfaCardCouponList.forEach(it => {
          if (it.amountRecharge > 0) {
            // 实收金额 = 充值金额 * 折扣
            //  it.amount = (it.amountRecharge * this.averageDiscount).toFixed(4);
            it.amount = (it.amountRecharge * calcAverageDiscount).toFixed(4)
            it.discount = (it.amount / it.amountRecharge).toFixed(4);
          } else {
            // 如果 amountRecharge 为 0，则直接将 discount 设为 0，否则进行计算
            it.amount = 0;
            it.discount = 0;
          }
        });
      });
      this.$emit('handleDiscount', this.averageDiscount)
      this.$emit('handleObject', this.sfaSaleDocketProductList); // 通过$emit方法触发自定义事件，并传递对象
    },
    handleEdit(e) {
      if (this.faIndex == 1 || this.faType == 4) {
        this.editObj.sfaCardCouponMenuList[0].menuShow = 1;
      }
      if (this.editObj.customized == 1 && !this.editObj.customerId) {
        this.$message.warning('请选择客户！')
        return false;
      }
      if (!this.editObj.sfaCardCouponMenuList[0].menu && this.editObj.sfaCardCouponMenuList.length <= 1) {
        this.$message.warning('请选择开通栏目！')
        return false;
      }
      if (!this.editObj.startTime || !this.editObj.endTime) {
        this.$message.warning('请选择有效期！')
        return false;
      }
      if (this.editObj.amount == 'undefined') {
        this.$message.warning('请输入单张实收金额！')
        return false;
      }
      if (this.$route.query.pageType == 'copy' && !this.editObj.style) {
        this.$message.warning('请选择卡券样式')
        return false;
      }
      if (!this.editObj.invoice) {
        this.$message.warning('请选择是否需要发票！')
        return false;
      }
      // 校验每个栏目是否选择显示按钮
      if (this.editObj.sfaCardCouponMenuList.length > 0) {
        let isBo = this.hasMenuShow(this.editObj.sfaCardCouponMenuList, 1)
        let isMenu = this.hasMenuShow(this.editObj.sfaCardCouponMenuList, 2)
        if (!isMenu) {
          this.$message.warning('请选择栏目名称！')
          return false;
        }
        if (!isBo) {
          this.$message.warning('请选择栏目是否显示按钮！')
          return false;
        }
      }
      // 检查次票增值服务金额
      const hasError = this.editObj.sfaCardCouponMenuList.some(item => {
        if (item.menu === 5 && this.faIndex == 2) {
          if (!item.incrementAmount) {
            this.$message.error('请输入增值服务抵扣金额');
            return true; // 中止循环
          } else {
            if (item.incrementAmount - 0 > this.editObj.amountRecharge) {
              this.$message.error('增值服务抵扣金额不可大于单张充值金额！');
              return true; // 中止循环
            }
          }
        }
      });
      if (hasError) {
        return false;
      }
      e.preventDefault()
      this.$refs.ruleEditForm.validate(valid => {
        if (valid) {
          this.sfaSaleDocketProductList[this.faIndex].sfaCardCouponList[this.editIndex] = this.editObj;
          this.calcDiscount()
          this.EditVisible = false;
          this.editObj = {}
          // this.$emit('handleObject', this.sfaSaleDocketProductList); // 通过$emit方法触发自定义事件，并传递对象
        } else {
          return false;
        }
      });
    },
    // 是否确认删除
    delConfirm(record, faIndex) {
      let index = this.getIndex(record);
      this.sfaSaleDocketProductList[faIndex].sfaCardCouponList.splice(index, 1)
      this.calcDiscount();
      // this.$emit('handleObject', this.sfaSaleDocketProductList); // 通过$emit方法触发自定义事件，并传递对象
    },
    birthdayChange(date, dateString) {
      this.form.birthday = dateString
    },
    birthdayEdit(date, dateString) {
      this.editObj.birthday = dateString
    },


    selectChange(e) {
      console.log(e, 'e')
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}

.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}


.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}


@media (max-width: 1800px) {
  ::v-deep .ant-card-body {
    padding: 12px !important;
    // zoom: .8 !important;
  }

  ::v-deep .ant-col-md-4 {
    // width: 16% !important;
    // margin-right: 20px;
  }
}


@media (max-width: 1600px) {
  ::v-deep .ant-col-md-4 {
    width: 18% !important;
  }
}


.select-btn {
  width: 200px;
  height: 114px;
  background: rgba(219, 219, 219, 0.17);
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  display: flex;
}

.select-btn p {
  background: rgba(255, 255, 255, 0.14);
  border-radius: 8px;
  border: 1px dashed #5542F6;
  width: 106px;
  margin: auto;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  text-align: center;
  color: #5542F6;
}

.question-icon {
  width: 16px;
  cursor: pointer;
  height: 16px;
  color: #999;
  position: absolute;
  bottom: 2px;
  right: -18px;
}

.ipt-text {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.lb-box p {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #000000;
}

.lm-box {
  padding: 24px 0;
  box-sizing: border-box;
  border-top: 1px solid #EBEAED;
  border-bottom: 1px solid #EBEAED;
}

ul {
  list-style: none;
}


::v-deep .ant-input[disabled] {
  background: #eee;
  color: #333;
}

::v-deep .ant-select-disabled .ant-select-selection {
  background: #eee !important;
  color: #333;
}

.sel-box .ant-modal-content {
  z-index: 9999999;
}

::v-deep .ant-calendar-footer-btn .ant-tag-blue {
  color: #5542F6 !important;
}

::v-deep .ant-calendar-footer-btn .ant-tag {
  color: #5542F6 !important;
}
</style>